import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import useCommonSelector from 'Common/utils/use-selector';
import { useGetWidgetDataByIdMutation } from 'Dashboard/store/api';
import { Button, Typography, Grid, Alert } from '@mui/material';
import CommonLoading from 'Common/components/Loading';
import DataGridTable from 'shared/components/DataGridTable';
import FormattedMessage from 'shared/components/FormattedMessage';
import WidgetContainer from 'Common/components/Widgets/components/WidgetContainer';
import { getInitialFilterLoad, selectdashboardFilter } from 'Dashboard/store';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import RiskChip from 'Risk/components/RiskChip';
import NoDataToDisplayCard from '../NoDataCard';
import { AuthorizedContent } from '@frontegg/react';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import CampaignCreateModal from 'Risk/components/CampaignCreateModal';
import { useCreateCampaignMutation } from 'Risk/store/api';
import { CampaignCreateFormValues } from 'shared/models/data/campaigns.model';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import { useNavigate } from 'react-router-dom';
import { GridType } from 'Risk/store';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';

interface TopCampaignsTableWidgetProps
  extends BaseComponentProps,
    OrganizationalDataProps {}

export const TopCampaignsTableWidget: FunctionComponent<
  TopCampaignsTableWidgetProps
> = ({ scopeData, groupData }) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const [selectedFindingType, setSelectedFindingType] = useState<string>('');
  const [selectedFindingTitle, setSelectedFindingTitle] = useState<string>('');
  const [openCreateCampaignModal, setOpenCreateCampaignModal] =
    useState<boolean>(false);
  const [getWidgetData, { isLoading, data: widgetDataSet }] =
    useGetWidgetDataByIdMutation();

  const filter = useCommonSelector(selectdashboardFilter);
  useEffect(() => {
    if (dashboardFilterInitialLoad) {
      let filterPayload = {
        businessUnitId: filter.businessUnitId,
        findingType: filter.findingTypes,
      };
      if (filter.findingTypes.length === 0)
        delete (filterPayload as { findingType?: any }).findingType;
      getWidgetData({
        id: 'top_campaigns',
        searchBody: { filter: filterPayload },
      });
    }
  }, [dashboardFilterInitialLoad, filter.businessUnitId, filter.findingTypes]);

  const [
    createCampaign,
    {
      data: createCampaignPayload,
      error: createCampaignError,
      isLoading: createCampaignLoading,
      isSuccess: createCampaignSuccess,
      reset: createCampaignReset,
    },
  ] = useCreateCampaignMutation();

  useEffect(() => {
    if (createCampaignSuccess) {
      createCampaignReset();
      navigate('/campaigns');
    }
  }, [createCampaignPayload]);

  const topCampaignsTableColumns: Array<GridColDef> = [
    {
      field: 'findingTitle',
      headerName: translation('dashboards.widgets.topCampaigns.potentialScore'),
      flex: 8,
      headerAlign: 'left',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          maxWidth={550}
        >
          {params?.value}
        </TextOverflow>
      ),
    },
    {
      field: 'max_risk',
      headerName: translation('dashboards.widgets.topCampaigns.riskScore'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div className="chip-cell">
          <RiskChip riskScore={params?.value} />
        </div>
      ),
    },
    {
      field: 'affected_assets',
      headerName: translation('dashboards.widgets.topCampaigns.numbOfRisks'),
      flex: 1,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => (
        <Typography>{params?.value}</Typography>
      ),
    },
    {
      field: 'create_campaign',
      headerName: '',
      flex: 2,
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <AuthorizedContent
          requiredPermissions={[ApplicationPermission.CAMPAIGNS_WRITE]}
        >
          <Button
            className="base-opus-text-button action-button"
            onClick={() => handleCreateCampaignModalOpen(params)}
            variant="text"
          >
            <FormattedMessage
              id="dashboards.widgets.topCampaigns.startCampaign"
              defaultMessage="Start Campaign"
              capitalize
            />
          </Button>
        </AuthorizedContent>
      ),
    },
  ];

  const handleCreateCampaignModalOpen = (params: GridRenderCellParams) => {
    setOpenCreateCampaignModal(true);
    setSelectedFindingType(params.row.findingType);
    setSelectedFindingTitle(params.row.findingTitle);
  };

  const handleCreateCampaignModalClose = () => {
    setOpenCreateCampaignModal(false);
  };

  const handleCampaignCreation = (
    campaignCreatePayload: CampaignCreateFormValues
  ) => {
    createCampaign({
      ...campaignCreatePayload,
      startTime: campaignCreatePayload.startTime[0],
      endDate: campaignCreatePayload.endDate[0],
      filter: {
        type: [],
      },
      attachedItems: [],
      selectionProps: {
        gridType: GridType.Risk,
        indeterminateFindingTypes: [],
        selectedFindingIds: [],
        selectedFindingTypes: [selectedFindingType],
        unselectedFindingIds: [],
      },
    });
    handleCreateCampaignModalClose();
  };

  const businessUnitFilter = useMemo<
    Partial<{ businessUnitId: Array<AutocompleteOption> }>
  >(() => {
    const selectedServices = filter.businessUnitItems?.filter(
      (businessUnitItem) => {
        return filter.selectedServices.find(
          (serviceId) => businessUnitItem.id === serviceId
        );
      }
    );

    return filter.presentationalBusinessUnitId?.includes('all')
      ? selectedServices?.length
        ? {
            businessUnitId: selectedServices?.map((selectedService) => ({
              value: selectedService.id,
              label: selectedService.name,
            })),
          }
        : {}
      : {
          businessUnitId: filter.businessUnitItems?.map((selectedService) => ({
            value: selectedService.id,
            label: selectedService.name,
          })),
        };
  }, [filter.businessUnitId, filter.selectedServices]);

  const groupFilter = useMemo<
    Partial<{ groupId: Array<AutocompleteOption> }>
  >(() => {
    if (businessUnitFilter.businessUnitId) return {};

    const selectedGroup: OrganizationNode | undefined = groupData?.find(
      (groupDataItem: OrganizationNode) =>
        filter.selectedGroup?.includes(groupDataItem.id)
    );

    return selectedGroup
      ? {
          groupId: [
            {
              value: selectedGroup.id,
              label: selectedGroup.name,
            },
          ],
        }
      : {};
  }, [filter.selectedGroup, groupData, businessUnitFilter]);

  const scopeFilter = useMemo<
    Partial<{ scopeId: Array<AutocompleteOption> }>
  >(() => {
    if (businessUnitFilter.businessUnitId) return {};

    const selectedScope: OrganizationNode | undefined = scopeData?.find(
      (scopeDataItem: OrganizationNode) =>
        filter.selectedScope?.includes(scopeDataItem.id)
    );

    return selectedScope
      ? {
          scopeId: [
            {
              value: selectedScope.id,
              label: selectedScope.name,
            },
          ],
        }
      : {};
  }, [filter.selectedScope, scopeData, businessUnitFilter]);

  const findingTypesFilter = useMemo<
    Partial<{ findingType: Array<AutocompleteOption> }>
  >(() => {
    if (filter.findingTypes.length) {
      return {
        findingType: filter.findingTypes.map((findingType) => ({
          value: findingType,
          label: findingType,
        })),
      };
    }

    return {};
  }, [filter.findingTypes]);

  return (
    <>
      {(createCampaignLoading || createCampaignError) && (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Alert severity={createCampaignLoading ? 'info' : 'error'}>
            {createCampaignLoading ? (
              <FormattedMessage
                id="campaigns.modal.validationErrors.creatingCampaign"
                defaultMessage={''}
              />
            ) : (
              <FormattedMessage
                id="campaigns.modal.validationErrors.creatingCampaignFailed"
                defaultMessage={''}
              />
            )}
          </Alert>
        </CommonSnackbarContainer>
      )}

      <Grid
        className="top_risk_owners_table_widget dashboard-table-widget"
        height={'425px'}
        minHeight={'425px'}
      >
        <WidgetContainer
          title={translation(`dashboards.widgets.topCampaigns.title`)}
        >
          {widgetDataSet && !widgetDataSet.length && !isLoading ? (
            <NoDataToDisplayCard />
          ) : (
            <DataGridTable
              rowHeight={59}
              components={{
                LoadingOverlay: CommonLoading,
              }}
              loading={isLoading}
              rows={widgetDataSet || []}
              columns={topCampaignsTableColumns}
              getRowId={(row) => {
                return row?.findingType;
              }}
              hideFooter
              disableColumnMenu
              disableColumnSelector
              isRowSelectable={() => false}
              isCellEditable={() => false}
            />
          )}
        </WidgetContainer>
        <CampaignCreateModal
          campaignName={selectedFindingTitle}
          modalOpen={openCreateCampaignModal}
          handleModalClose={handleCreateCampaignModalClose}
          handleCampaignCreation={handleCampaignCreation}
          additionalFilters={{
            type: [
              {
                value: selectedFindingType,
                label: selectedFindingType,
              },
            ],
            ...scopeFilter,
            ...groupFilter,
            ...businessUnitFilter,
            ...findingTypesFilter,
          }}
        />
      </Grid>
    </>
  );
};
