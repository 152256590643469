import { FunctionComponent, MouseEvent, useState } from 'react';
import {
  DateRangeState,
  ExtendedFilterCategory,
  FilterType,
  KeyValueSelection,
  KeyValueState,
  MultiSelectState,
  NumberRangeState,
  NumberState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { FilterChipGroupChangeHandlerProps } from '../FilterChipGroup/FilterChipGroup';
import FilterContentDropdown from '../FilterContentDropdown';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

interface FilterChipProps
  extends BaseComponentProps,
    ExtendedFilterCategory,
    FilterChipGroupChangeHandlerProps {}

export const FilterChip: FunctionComponent<FilterChipProps> = ({
  categoryState,
  categories,
  id,
  label,
  onChange,
  state,
  type,
  onRemoveChip,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [displayCloseIcon, setDisplayCloseIcon] = useState<boolean>(false);

  const handleChipClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChipPopoverClose = () => {
    setAnchorEl(null);
  };

  const getValueLabel = (): string => {
    if (type === FilterType.SINGLE_SELECT) {
      const typedState = state as SingleSelectState;

      return typedState.selectedOptions?.length
        ? typedState.selectedOptions[0]?.label ||
            typedState.selectedOptions[0]?.value
        : '';
    }

    if (type === FilterType.MULTI_SELECT) {
      const typedState = state as MultiSelectState;

      if (typedState.allSelected) return 'All';

      return typedState.selectedOptions?.length
        ? typedState.selectedOptions?.length > 1
          ? `${typedState.selectedOptions?.length} Selected`
          : typedState.selectedOptions[0]?.label ||
            typedState.selectedOptions[0]?.value
        : '';
    }

    if (type === FilterType.DATE_RANGE) {
      const typedState = state as DateRangeState;

      return typedState.endDate && typedState.startDate
        ? `${moment(typedState.startDate).format(dateFormat)} to ${moment(
            typedState.endDate
          ).format(dateFormat)}`
        : '';
    }

    if (type === FilterType.NUMBER_RANGE) {
      const typedState = state as NumberRangeState;

      return Number.isNaN(typedState.endValue) &&
        Number.isNaN(typedState.startValue)
        ? ''
        : `${typedState.startValue} - ${typedState.endValue}`;
    }

    if (type === FilterType.NUMBER) {
      const typedState = state as NumberState;

      return typedState.value ? String(typedState.value) : '';
    }

    if (type === FilterType.KEY_VALUE) {
      const typedState = state as KeyValueState;

      if (typedState.selections?.length === 0) return '';

      const validSelections = typedState.selections?.filter(
        (selection) => selection.key.value.length > 0
      );

      if (
        validSelections?.length === 1 &&
        validSelections[0].key.value.length === 0 &&
        validSelections[0].values?.length === 0
      )
        return '';

      if (validSelections && validSelections?.length > 1) {
        return `${validSelections.length} Selected`;
      }

      return (
        validSelections
          ?.map(
            (selection: KeyValueSelection) =>
              `${selection.key.value} : ${selection.values
                ?.map((valueOption) => valueOption.value)
                .join(',')}`
          )
          .join(',') || ''
      );
    }

    return '';
  };

  const renderFilterChipDropdownIcon = () => {
    return (
      <div className="filter-chip-dropdown-icon">
        <OpusSvgIcon type={SVG_ICON_TYPES.SORT_DESCENDING_ICON} />
      </div>
    );
  };

  const renderFilterChipRemoveButton = () => {
    if (onRemoveChip)
      return (
        <div
          className="filter-chip-remove-icon"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onRemoveChip(id);
          }}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />
        </div>
      );

    return <></>;
  };

  const valueLabel = getValueLabel();

  return (
    <>
      <div
        className="filter-chip"
        onClick={handleChipClick}
        onMouseEnter={() => {
          if (onRemoveChip) setDisplayCloseIcon(true);
        }}
        onMouseLeave={() => {
          if (onRemoveChip) setDisplayCloseIcon(false);
        }}
      >
        <div className="filter-chip-text">
          <div className="filter-chip-label">{`${label}${
            valueLabel?.length ? ':' : ''
          }`}</div>

          <div className="filter-chip-value" title={valueLabel}>
            {valueLabel}
          </div>
        </div>

        <div className="filter-chip-icons">
          {state &&
          (state as SingleSelectState)?.selectedOptions?.length &&
          displayCloseIcon
            ? renderFilterChipRemoveButton()
            : renderFilterChipDropdownIcon()}
        </div>
      </div>
      <FilterContentDropdown
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        categoryState={categoryState}
        handleClose={handleChipPopoverClose}
        id={id}
        label={label}
        onChange={onChange}
        state={state}
        type={type}
        categories={categories}
      />
    </>
  );
};
