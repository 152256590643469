import { CircularProgress, IconButton, TextField } from '@mui/material';
import { useGetCampaignListMutation } from 'Campaigns/store/api';
import { OrderState, OrderType } from 'Common/utils/sort';
import { RowClickedEvent } from 'ag-grid-community';
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import {
  campaignFilterCategories,
  campaignTableColumns,
} from 'shared/fixtures/data/campaigns-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  CampaignCriticality,
  CampaignModel,
  CampaignStatus,
} from 'shared/models/data/campaigns.model';
import {
  CategoryState,
  ExtendedFilterCategory,
  MultiSelectState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

const advancedFilterHandler = new AdvanceFilterHandler();

interface CampaignsManagmentPageProps extends BaseComponentProps {}

const getSearchKeywordFromUrl = () => {
  const search = window.location.search;

  if (search.length) {
    const parsedSearchParams = new URLSearchParams(search);

    return parsedSearchParams.get('searchKeyword') || '';
  }

  return '';
};

const getCampaignFilterStateFromUrl = () => {
  const searchKeyword = window.location.search;

  if (searchKeyword.length) {
    const parsedSearchParams = new URLSearchParams(searchKeyword);

    if (parsedSearchParams.get('campaignFilter')) {
      const parsedFilters = JSON.parse(
        parsedSearchParams.get('campaignFilter') || ''
      );

      const filterState =
        advancedFilterHandler.translateQueryFiltersToFilterState(
          parsedFilters,
          campaignFilterCategories
        );

      return filterState;
    }
  }

  return {};
};

const getPaginatedCampaignItems = (
  items: Array<any>,
  pageNumber: number,
  pageSize: number
) => {
  return items.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize);
};

const enrichFilterCategoriesWithOwnerData = (
  filterCategories: Array<ExtendedFilterCategory>,
  data: Array<CampaignModel>
) => {
  const uniqueOwners = data
    .filter(
      (
        dataItem: CampaignModel,
        index: number,
        dataList: Array<CampaignModel>
      ) =>
        index ===
        dataList.findIndex(
          (dataListItem: CampaignModel) =>
            dataListItem.ownerUser?.id === dataItem.ownerUser?.id
        )
    )
    .filter((dataItem: CampaignModel) => dataItem.ownerUser)
    .map((dataItem: CampaignModel) => ({
      value: dataItem.ownerUser.id,
      label: dataItem.ownerUser.name,
    }));

  return filterCategories.map((category) => {
    if (category.id === 'ownerUserId') {
      return {
        ...category,
        state: {
          ...category.state,
          source: {
            options: uniqueOwners,
          },
        },
      };
    }

    return category;
  });
};

const getFilteredCampaigns = (
  campaignList: Array<CampaignModel>,
  filters: Record<string, CategoryState>,
  searchKeyword: string
) => {
  const ownerUserIdFilter = filters.ownerUserId as MultiSelectState;
  const statusFilter = filters.status as MultiSelectState;
  const priorityFilter = filters.priority as MultiSelectState;
  const typeFilter = filters.type as SingleSelectState;

  const selectedOwners = ownerUserIdFilter
    ? ownerUserIdFilter.allSelected
      ? null
      : ownerUserIdFilter.selectedOptions?.map((option) => option.value)
    : null;

  const selectedStatuses = statusFilter
    ? statusFilter.allSelected
      ? null
      : statusFilter.selectedOptions?.map((option) => option.value)
    : null;

  const selectedPriorities = priorityFilter
    ? priorityFilter.allSelected
      ? null
      : priorityFilter.selectedOptions?.map((option) => option.value)
    : null;

  const selectedTypes = typeFilter
    ? typeFilter.selectedOptions?.map((option) => option.value)
    : null;

  return campaignList
    .filter((campaign) => {
      let condition = true;

      if (selectedOwners?.length) {
        condition = condition && selectedOwners.includes(campaign.ownerUser.id);
      }

      if (selectedPriorities?.length) {
        condition = condition && selectedPriorities.includes(campaign.priority);
      }

      if (selectedStatuses?.length) {
        condition = condition && selectedStatuses.includes(campaign.status);
      }

      if (selectedTypes?.length) {
        condition = condition && selectedTypes.includes(campaign.type);
      }

      return condition;
    })
    .filter((campaign) =>
      campaign.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
};

const getSortedCampaigns = (
  campaignList: Array<CampaignModel>,
  orderState: OrderState | null
) => {
  if (!orderState) return campaignList;

  const orderField = orderState.field;
  const orderType = orderState.type;

  const campaignOrderField = orderField as keyof CampaignModel;

  const priorityOrder = [
    CampaignCriticality.LBI,
    CampaignCriticality.MBI,
    CampaignCriticality.HBI,
    CampaignCriticality.CBI,
  ];

  return campaignList.sort((campaignA, campaignB) => {
    let comparison = 0;

    if (orderField === 'priority') {
      comparison =
        priorityOrder.indexOf(campaignA.priority) -
        priorityOrder.indexOf(campaignB.priority);
    } else if (orderField === 'ownerUserId') {
      comparison = campaignA.ownerUser.name.localeCompare(
        campaignB.ownerUser.name
      );
    } else {
      if (campaignA[campaignOrderField] > campaignB[campaignOrderField]) {
        comparison = 1;
      } else if (
        campaignA[campaignOrderField] < campaignB[campaignOrderField]
      ) {
        comparison = -1;
      }
    }

    return orderType === OrderType.ASC ? comparison : -comparison;
  });
};

export const CampaignsManagmentPage: FunctionComponent<
  CampaignsManagmentPageProps
> = () => {
  const gridRef = useRef();

  const { t: translation } = useTranslation();

  const navigate = useNavigate();

  const [
    getCampaignList,
    { isLoading: campaignListLoading, data: campaignList },
  ] = useGetCampaignListMutation();

  const [urlSearchParams, , getSearchParams] = useQueryParams();

  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [displayCampaignCreationBanner, setDisplayCampaignCreationBanner] =
    useState<boolean>(false);

  const [searchKeyword, setSearchKeyword] = useState<string>(
    getSearchKeywordFromUrl
  );

  const areSomeCampaignsCreating = useMemo<boolean>(() => {
    if (campaignList) {
      return campaignList.some(
        (campaign) => campaign.status === CampaignStatus.CREATING
      );
    }

    return false;
  }, [campaignList]);

  useEffect(() => {
    try {
      if (!reactiveSearchParams.get('order')) {
        const queryParams = getSearchParams();

        setReactiveSearchParams({
          ...queryParams,
          order: JSON.stringify({
            field: 'remainingDays',
            type: OrderType.ASC,
          }),
        });
      }
    } catch (err) {
      console.log('Error when setting default order params', err);
    }
  }, []);

  const orderParams = useMemo<OrderState | null>(() => {
    try {
      if (reactiveSearchParams.get('order')) {
        const parsedOrderParams = JSON.parse(
          reactiveSearchParams.get('order') as string
        ) as OrderState;

        return parsedOrderParams;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [reactiveSearchParams.get('order')]);

  useEffect(() => {
    if (areSomeCampaignsCreating) setDisplayCampaignCreationBanner(true);
  }, [areSomeCampaignsCreating]);

  const [campaignFilterState, setCampaignFilterState] = useState<
    Record<string, CategoryState>
  >(getCampaignFilterStateFromUrl);

  const onCampaignFilterChange = (categoryId: string, state: CategoryState) => {
    setCampaignFilterState((prevCampaignFilterState) => {
      if (Object.keys(state).length > 0) {
        return {
          ...prevCampaignFilterState,
          [categoryId]: state,
        };
      }

      return { ...prevCampaignFilterState };
    });
  };

  const onCampaignFilterClear = (categoryId: string) => {
    setCampaignFilterState((prevCampaignFilterState) => {
      const updatedFilterState = { ...prevCampaignFilterState };

      if (categoryId) delete updatedFilterState[categoryId];

      return { ...updatedFilterState };
    });
  };

  useEffect(() => {
    getCampaignList('');
  }, []);

  useEffect(() => {
    const currentSearchParams = getSearchParams();

    setReactiveSearchParams({
      ...currentSearchParams,
      searchKeyword,
    });
  }, [searchKeyword]);

  const resetPagination = () => {
    setPageNumber(1);
    setPageSize(10);
  };

  useEffect(() => {
    resetPagination();

    const queryFilters =
      advancedFilterHandler.translateFilterStateSelectionsToQueryFilters(
        campaignFilterState,
        campaignFilterCategories
      );

    const currentSearchParams = getSearchParams();

    setReactiveSearchParams({
      ...currentSearchParams,
      campaignFilter: JSON.stringify(queryFilters),
    });
  }, [campaignFilterState]);

  const onSortChanged = (sortedColumns: Array<OrderState>) => {
    const existingParams = getSearchParams();

    if (sortedColumns.length) {
      setReactiveSearchParams({
        ...existingParams,
        order: JSON.stringify({
          field: sortedColumns[0].field,
          type: sortedColumns[0].type,
        }),
      });
    } else {
      if (existingParams) delete existingParams.order;

      setReactiveSearchParams({
        ...existingParams,
      });
    }
  };

  const items = getSortedCampaigns(
    getFilteredCampaigns(
      campaignList || [],
      campaignFilterState,
      searchKeyword
    ),
    orderParams
  );

  const renderCampaignCreationBanner = () => {
    if (displayCampaignCreationBanner)
      return (
        <div className="campaigns-management-page-body-creation-banner">
          <div>
            <OpusSvgIcon type={SVG_ICON_TYPES.CLOCK_ICON} />
            <span>
              Campaign creation in progress. Please check back later or refresh
              the page to see the current status.
            </span>
          </div>

          <IconButton
            onClick={() => {
              setDisplayCampaignCreationBanner(false);
            }}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />
          </IconButton>
        </div>
      );

    return <></>;
  };

  return (
    <div className="campaigns-management-page">
      <HeaderComponent text="Campaigns" />

      <div className="campaigns-management-page-body">
        {renderCampaignCreationBanner()}
        <div className="campaigns-management-page-body-filters">
          <TextField
            className={'filter-input filter-main-input'}
            placeholder={translation(`common.searchPlaceholder`)}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              setSearchKeyword(value);
              resetPagination();
            }}
            InputProps={{
              className: 'search-filter-input',
              startAdornment: (
                <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
              ),
            }}
            value={searchKeyword}
          />
          <FilterChipGroup
            categories={enrichFilterCategoriesWithOwnerData(
              campaignFilterCategories,
              campaignList || []
            )}
            categoryState={campaignFilterState}
            onChange={onCampaignFilterChange}
            staticCategoryIds={['ownerUserId', 'priority', 'status', 'type']}
            disableAddButton
            onClearChipState={onCampaignFilterClear}
          />
        </div>
        <div className="campaigns-management-page-body-table">
          <CommonSimpleDataGrid
            isLoading={campaignListLoading}
            gridRef={gridRef}
            rowData={
              campaignListLoading
                ? undefined
                : getPaginatedCampaignItems(items, pageNumber - 1, pageSize)
            }
            defaultColDef={{
              resizable: true,
              sortable: true,
            }}
            columnDefs={campaignTableColumns}
            otherComponents={{
              NoDataBackdropComponent: (
                <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
              ),
            }}
            loadingOverlayComponent={() => <CircularProgress />}
            paginationProps={{
              totalItems: items?.length || 0,
              pageSize,
              currentPage: pageNumber,
              onPageChange: (selectedPage) => {
                setPageNumber(selectedPage);
              },
              onPageSizeChange: (selectedPageSize) => {
                setPageSize(selectedPageSize);
              },
            }}
            context={{
              resetCampaigns: () => {
                getCampaignList('');
              },
            }}
            getRowId={(params) => params.data.id}
            onSort={onSortChanged}
            sortModel={orderParams as OrderState}
            onRowClicked={(event: RowClickedEvent) => {
              const gridActions = event.eventPath?.find(
                (eventPathItem: EventTarget) =>
                  (eventPathItem as HTMLElement)?.classList?.contains(
                    'campaign-grid-actions'
                  )
              );

              if (event.data?.status !== 'Creating' && !Boolean(gridActions)) {
                navigate(`/campaigns/${event.data.id}`);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
