import { AutocompleteOption } from 'FindingDetails/store/api';
import { RuleProperty } from 'Settings/interfaces/RiskCustomizationConfig';
import {
  inRangePropertyNames,
  notificationRuleConditionOptions,
  notificationRuleExceptionTriggerOptions,
  notificationRuleSLATriggerOptions,
  notificationRuleTicketTriggerOptions,
  notificationRuleTriggerOptions,
} from 'shared/fixtures/data/notification-rule.data';
import {
  CategoryState,
  ExtendedFilterCategory,
} from 'shared/models/data/data-filter.model';
import {
  NotificationRule,
  NotificationRuleAction,
  NotificationRuleActionType,
  NotificationRuleEmailModel,
  NotificationRuleLabelsModel,
  NotificationRuleMessageModel,
  NotificationRuleProperty,
  NotificationRuleTicketAction,
  NotificationRuleTicketModel,
  NotificationRuleTrigger,
  NotificationRuleWorkflowsModel,
  NotificationSupportedRoles,
  SelectOptionModel,
  TeamRoleOptionsList,
  UserRoleOptionsList,
} from 'shared/models/data/notification-rule.model';
import { SettingsDataHandler } from './settings-data.handler';
import { AdvanceFilterHandler } from './advance-filter-data.handler';

const advancedFilterHandler = new AdvanceFilterHandler();

export type NotificationRuleBaseFormData = Record<
  'name' | 'description' | 'project',
  string
>;

export type NotificationRuleAdditionalFormData = {
  properties: Record<string, CategoryState>;
  triggers: Array<AutocompleteOption>;
  emails: Array<AutocompleteOption>;
  emailRoles: Array<AutocompleteOption>;
  teamRoles: Array<AutocompleteOption>;
  teams: Array<AutocompleteOption>;
  labels: Array<AutocompleteOption>;
  flows: Array<AutocompleteOption>;
  resourceOwner: boolean;
  openTicket: boolean;
  closedTicket: boolean;
};

export type NotificationRuleDefaultFormData = NotificationRuleBaseFormData &
  NotificationRuleAdditionalFormData;

export class NotificationRuleDataHandler extends SettingsDataHandler {
  private getFlows(
    actions: Array<NotificationRuleAction>,
    flowsActionType: NotificationRuleActionType.WORKFLOWS
  ) {
    const flows = actions
      .filter((action) => action.type === flowsActionType)
      .map((action) => {
        return (action.data as NotificationRuleWorkflowsModel)
          .workflowDefsFamilyIds;
      })
      .flat()
      .map((flowId) => ({ value: flowId }));

    return flows;
  }

  private getLabels(
    actions: Array<NotificationRuleAction>,
    labelsActionType: NotificationRuleActionType.LABELS
  ): Array<AutocompleteOption> {
    const labels = actions
      .filter((action) => action.type === labelsActionType)
      .map((action) => {
        return (action.data as NotificationRuleLabelsModel).labels;
      })
      .flat()
      .map((label) => ({ value: label, label }));

    return labels;
  }

  private getEmailsAndRoles(
    actions: NotificationRuleAction[],
    emailActionType: NotificationRuleActionType.EMAIL
  ) {
    const emails = actions
      .filter((action) => action.type === emailActionType)
      .map((action) => {
        return (action.data as NotificationRuleEmailModel).recipients;
      })
      .flat()
      .map((userEmail) => ({
        value: userEmail,
        label: userEmail,
      }));

    const roles = actions
      .filter((action) => action.type === emailActionType)
      .map((action) => {
        return (action.data as NotificationRuleEmailModel).roles;
      })
      .flat()
      .map((role) => ({
        value: role,
        id: role,
        label: UserRoleOptionsList.find((option) => option.id === role)?.label,
      })) as Array<AutocompleteOption>;

    return { emails, roles };
  }

  private getTeamsAndRoles(
    actions: NotificationRuleAction[],
    messageActionType: NotificationRuleActionType.MESSAGE
  ) {
    const teams = actions
      .filter((action) => action.type === messageActionType)
      .map((action) => {
        return (action.data as NotificationRuleMessageModel).recipients;
      })
      .flat();

    const roles = actions
      .filter((action) => action.type === messageActionType)
      .map((action) => {
        return (action.data as NotificationRuleMessageModel).roles;
      })
      .flat()
      .map((role) => ({
        value: role,
        id: role,
        label: TeamRoleOptionsList.find((option) => option.id === role)?.label,
      })) as Array<AutocompleteOption>;

    return { teams, roles };
  }

  transformNotificationRuleDataToFormData(
    notificationRule: NotificationRule
  ): NotificationRuleDefaultFormData {
    let ruleProperties: Record<string, CategoryState> =
      this.transformRulePropertiesToFormProperties(notificationRule.properties);

    const { emails: emailRecipients, roles: emailRoles } =
      this.getEmailsAndRoles(
        notificationRule.actions,
        NotificationRuleActionType.EMAIL
      );
    const { teams: messageRecipients, roles: teamRoles } =
      this.getTeamsAndRoles(
        notificationRule.actions,
        NotificationRuleActionType.MESSAGE
      );

    const labels = this.getLabels(
      notificationRule.actions,
      NotificationRuleActionType.LABELS
    );

    const flows = this.getFlows(
      notificationRule.actions,
      NotificationRuleActionType.WORKFLOWS
    );

    const triggers = [
      ...notificationRuleTriggerOptions,
      ...notificationRuleTicketTriggerOptions,
      ...notificationRuleExceptionTriggerOptions,
      ...notificationRuleSLATriggerOptions,
    ].filter((triggerOption) =>
      notificationRule.triggers?.includes(
        triggerOption.value as NotificationRuleTrigger
      )
    );

    const resourceOwner: boolean = Boolean(
      notificationRule.actions.find(
        (action) => action.type === NotificationRuleActionType.RESOURCE_OWNER
      )
    );

    const ticketActions =
      (
        notificationRule.actions.find(
          (action) => action.type === NotificationRuleActionType.TICKET
        )?.data as NotificationRuleTicketModel
      )?.ticketActions || [];

    const projectId =
      (
        notificationRule.actions.find(
          (action) => action.type === NotificationRuleActionType.TICKET
        )?.data as NotificationRuleTicketModel
      )?.projectId || '';

    const openTicket = ticketActions.includes(
      NotificationRuleTicketAction.OPEN_TICKET
    );
    const closedTicket = ticketActions.includes(
      NotificationRuleTicketAction.CLOSE_TICKET
    );

    return {
      name: notificationRule.name,
      description: notificationRule.description as string,
      emails: emailRecipients,
      teams: messageRecipients,
      emailRoles,
      teamRoles,
      resourceOwner,
      openTicket,
      closedTicket,
      properties: ruleProperties,
      triggers,
      project: projectId,
      labels,
      flows,
    };
  }

  transformNotificationRuleFormDataToPostData(
    formData: NotificationRuleBaseFormData,
    additionalFormData: NotificationRuleAdditionalFormData,
    categories?: Array<ExtendedFilterCategory>
  ): NotificationRule {
    let actions: Array<NotificationRuleAction> = [];
    let triggers: Array<NotificationRuleTrigger> = [];

    let properties: Array<RuleProperty> =
      this.transformFormPropertiesToRuleProperties(
        additionalFormData.properties,
        categories
      );

    if (
      additionalFormData.emails?.length | additionalFormData.emailRoles?.length
    ) {
      actions.push({
        type: NotificationRuleActionType.EMAIL,
        data: {
          recipients: additionalFormData.emails?.map((email) => email.value),
          roles: additionalFormData.emailRoles?.map(
            (role) => role.value
          ) as unknown as Array<NotificationSupportedRoles>,
        },
      });
    }

    if (
      additionalFormData.teams?.length | additionalFormData.teamRoles?.length
    ) {
      actions.push({
        type: NotificationRuleActionType.MESSAGE,
        data: {
          recipients: additionalFormData.teams as Array<SelectOptionModel>,
          roles: additionalFormData.teamRoles?.map(
            (role) => role.value
          ) as unknown as Array<NotificationSupportedRoles>,
        },
      });
    }

    if (additionalFormData.labels?.length) {
      actions.push({
        type: NotificationRuleActionType.LABELS,
        data: {
          labels: additionalFormData.labels.map((labelItem) => labelItem.value),
        },
      });
    }

    if (additionalFormData.flows?.length) {
      actions.push({
        type: NotificationRuleActionType.WORKFLOWS,
        data: {
          workflowDefsFamilyIds: additionalFormData.flows.map(
            (flowOption) => flowOption.value
          ),
        },
      });
    }

    if (additionalFormData.resourceOwner) {
      actions.push({
        type: NotificationRuleActionType.RESOURCE_OWNER,
      });
    }

    if (additionalFormData.openTicket || additionalFormData.closedTicket) {
      const ticketActions = [];
      if (additionalFormData.openTicket)
        ticketActions.push(NotificationRuleTicketAction.OPEN_TICKET);
      if (additionalFormData.closedTicket)
        ticketActions.push(NotificationRuleTicketAction.CLOSE_TICKET);

      actions.push({
        type: NotificationRuleActionType.TICKET,
        data: {
          ticketActions,
          projectId: formData.project?.length ? formData.project : undefined,
        },
      });
    }

    if (additionalFormData.triggers?.length) {
      triggers = additionalFormData.triggers?.map(
        (trigger) => trigger.value
      ) as Array<NotificationRuleTrigger>;
    }

    return {
      name: formData.name,
      description: formData.description,
      actions,
      properties,
      triggers,
    };
  }

  transformTriggersToPresentationalSentence(
    triggers: Array<NotificationRuleTrigger>
  ): string {
    const triggerLabels = [
      ...notificationRuleTriggerOptions,
      ...notificationRuleExceptionTriggerOptions,
      ...notificationRuleSLATriggerOptions,
      ...notificationRuleTicketTriggerOptions,
    ]
      .filter((triggerOption) =>
        triggers?.includes(triggerOption.value as NotificationRuleTrigger)
      )
      .map((triggerOption) => triggerOption.label || triggerOption.value);

    return triggerLabels.join(' OR ');
  }

  transformPropertiesToPresentationalSentence(
    properties: Array<NotificationRuleProperty>
  ): string {
    let propertyTexts: Array<string> = [];

    properties.forEach((property, propertyIndex) => {
      const filterItem = advancedFilterHandler
        .extractAllCategories(notificationRuleConditionOptions)
        .find((filterItem) => filterItem.id === property.type);

      let checkedFilterOptions = [];

      if (inRangePropertyNames.includes(property.type)) {
        if (property.values.start && property.values.end)
          checkedFilterOptions = [property.values.start, property.values.end];
      } else {
        checkedFilterOptions = property.values.map(
          (valueItem: any) => valueItem.label || valueItem.value
        );
      }

      if (checkedFilterOptions.length > 0) {
        propertyTexts.push(
          `${filterItem?.label} is ${checkedFilterOptions?.join(' OR ')}`
        );
      }
    });

    return propertyTexts.join(' AND ');
  }
}
